import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { FormSection, FormModifier, Input } from 'hudl-uniform-ui-components';
import SectionPlatform from '../partials/_SectionPlatform';
import formModifierPreviewData from '../../../../data/previews/form-modifier.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Form Modifier"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Forms"
        tierThree="Form Modifier"
      />

      <PageNavigation>
        <PageNavigationLink>Density</PageNavigationLink>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Density">
        <Paragraph>
          More often than not, a form consists of many fields. When that
          happens, we need to show spacing some love. Choose one of two
          available styles to make those breaks consistent: standard or compact.
        </Paragraph>
        <ComponentPreview
          name="FormDensity"
          previewData={formModifierPreviewData.density}>
          <FormModifier>
            <FormSection sectionText="Shipping Address">
              <Input type="text" label="Street Address" />
              <Input type="text" label="State" />
              <Input type="text" label="Zip Code" />
            </FormSection>
          </FormModifier>
        </ComponentPreview>
      </Section>

      <Section title="Size">
        <Paragraph>
          Consider the number of form fields and interface density when choosing
          between the four available sizes.
        </Paragraph>
        <ComponentPreview
          name="FormSize"
          previewData={formModifierPreviewData.size}>
          <FormModifier>
            <FormSection sectionText="Shipping Address">
              <Input type="text" label="Street Address" />
              <Input type="text" label="State" />
              <Input type="text" label="Zip Code" />
            </FormSection>
          </FormModifier>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Buttons</SectionSubhead>
        <Paragraph>
          If a button sits on the same row as a form field, the sizes should
          match.
        </Paragraph>
        <Paragraph>
          If the button has a row all its own—like a single submit—use a large,{' '}
          <Link href="/components/buttons/button/design">primary style</Link> to
          make the CTA more clear.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="mix button and form field sizes for the sake of an obvious CTA"
            img="formmodifier-button-dont"
          />
          <DontDoItem
            type="do"
            text="match the button size with the related form fields."
            img="formmodifier-button-do"
          />
        </DontDo>
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
